.app__aboutus {
    position: relative;   
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: right;
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 2rem 0;
    color: var(--color-grey);
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}









/* ANIMATION CODE */


/* Initial styles */
.app__aboutus-content_about, .app__aboutus-content_history {
    opacity: 0;
    transform: translateX(-100px);
  }
  
  .app__aboutus-content_knife {
    opacity: 0;
    transform: translateY(-100px);
  }
  
  /* Animation triggers when .animate class is added */
  .app__aboutus-content.animate .app__aboutus-content_about,
  .app__aboutus-content.animate .app__aboutus-content_history {
    animation: slideIn 1s ease-out forwards;
  }
  
  .app__aboutus-content.animate .app__aboutus-content_knife {
    animation: dropIn 0.5s ease-out forwards;
    animation-delay: 0.5s; /* Ensures it animates after the text */
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes dropIn {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    60% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  


  